import React from 'react'
import styled from 'styled-components'

import Container from './Container'
import SectionHeader from './SectionHeader'
import GalleryWrapper from './GalleryWrapper'

import Button from './Button'

const GallerySectionStyles = styled.section`
  background-color: var(--lightgrey);
`

const GallerySectionLayoutStyles = styled.section`
  text-align: center;

  div.buttonContainer {
    margin-top: 8rem;
  }
`

export interface GallerySectionProps {
  data: any
}

const GallerySection = ({data}: GallerySectionProps) => {
  return (
    <GallerySectionStyles>
      <Container>
        <GallerySectionLayoutStyles>
          <SectionHeader>
            <>
              Our <strong>Gallery</strong>
            </>
          </SectionHeader>
          <GalleryWrapper {...data} count={8} />
          <div className={'buttonContainer'}>
            <Button link={'/gallery/'} text={'View all gallery images'} />
          </div>
        </GallerySectionLayoutStyles>
      </Container>
    </GallerySectionStyles>
  )
}

export default GallerySection
