import React, {ReactElement} from 'react'
import Img from 'gatsby-image'
import styled from 'styled-components'
import {useStaticQuery, graphql} from 'gatsby'

import {SlimContainer} from './Container'

const AttractionStyles = styled.section`
  grid-area: attractions;
`

const AttractionLayoutStyles = styled.section`
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: repeat(5, auto);
  grid-gap: 0;
  grid-template-areas:
    'stonehenge'
    'attractions'
    'cider'
    'museum'
    'langport';

  @media only screen and (min-width: 600px) {
    grid-template-columns: 700px auto;
    grid-template-rows: auto auto auto;
    grid-template-areas:
      'stonehenge attractions'
      'cider      museum'
      'langport   langport';
  }

  .stonehenge {
    grid-area: stonehenge;
    overflow: hidden;

    max-width: 100vw;
  }

  .attractions,
  .cider,
  .langport,
  .museum {
    h5 {
      font-size: var(--primary-header-font-size);
      font-weight: 300;

      strong {
        display: inline;
        font-size: var(--primary-header-font-size);
      }
    }

    p {
      font-size: var(--info-font-size);
      line-height: var(--info-line-height);
    }

    strong {
      font-size: var(--base-font-size);
      display: block;
      margin-top: 2rem;
    }
  }

  .attractions {
    padding: 3rem 2rem;

    @media only screen and (min-width: 600px) {
      padding: 4rem 6rem 0rem 8rem;
    }

    grid-area: attractions;

    h5 {
      color: var(--headergreen);
    }

    ul {
      font-size: var(--info-font-size);
      line-height: var(--info-line-height);
      li {
        margin-bottom: 0.3rem;
      }
    }
  }

  .cider {
    padding: 3rem 2rem;

    @media only screen and (min-width: 600px) {
      padding: 4rem 8rem 4rem 8rem;
    }

    grid-area: cider;
    background-color: var(--headergreen);
    color: var(--white);

    ul {
      font-size: var(--info-font-size);
      line-height: var(--info-line-height);
      list-style-type: none;
      margin: 0;
      padding: 2rem 0 0 0;
      width: 100%;

      li {
        display: block;
        margin-bottom: 0.3rem;
        font-weight: 200;

        a {
          float: right;
          text-decoration: none;
          color: var(--linkgreen);
        }
      }
    }
  }

  .langport {
    padding: 3rem 2rem;

    @media only screen and (min-width: 600px) {
      padding: 4rem 8rem 4rem 8rem;
    }

    grid-area: langport;
    background-color: var(--darkgreen);
    color: var(--white);
  }

  .museum {
    padding: 3rem 2rem;

    @media only screen and (min-width: 600px) {
      padding: 4rem 6rem 4rem 8rem;
    }

    grid-area: museum;
    background-color: var(--green);
    color: var(--white);

    p.fleet {
      margin-bottom: 0;
    }
    ul {
      padding: 0;
      font-size: var(--info-font-size);
      line-height: var(--info-line-height);
      list-style-type: none;

      a {
        text-decoration: underline;
        color: var(--black);
      }
    }
  }
`

const Attractions = (): ReactElement => {
  const data = useStaticQuery(graphql`
    query {
      stonehenge: file(name: {eq: "stonehenge"}) {
        childImageSharp {
          fixed(width: 700) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `)

  return (
    <AttractionStyles>
      <SlimContainer>
        <AttractionLayoutStyles>
          <Img
            className={'stonehenge'}
            fixed={data.stonehenge.childImageSharp.fixed}
            alt={'Photograph of the sun setting over the beautiful Stonehenge'}
          />
          <div className={'attractions'}>
            <h5>
              National <strong>Trust</strong>
            </h5>
            <p>
              There are a substantial amount of National Trust properties within
              one hours drive of Smith Cottages. Even if you’re not a member you
              can visit them and take a walk around some of the gardens, visit
              the shop and have lunch in the café. Their grounds are usually
              substantial and well worth a visit. To enter the property you
              would need to pay an entrance fee, but if you’re going to do that
              it’s probably worth paying the annual membership then you can
              visit as many properties as you would like, even when you return
              home.
            </p>
            <strong>Near by you will find:</strong>
            <ul>
              <li>Barrington Court</li>
              <li>Montacute</li>
              <li>Lytes Cary</li>
              <li>Tintinhull</li>
              <li>Dunster Castle</li>
              <li>Hardy’s Cottage</li>
            </ul>
          </div>
          <div className={'cider'}>
            <h5>
              Cider <strong>Farms</strong>
            </h5>
            <p>
              Somerset is famous for its Cider. There are many Cider farms close
              by. Opening times vary. Some are not open on Sundays.
            </p>
            <strong>The closest ones are:</strong>
            <ul>
              <li>
                Harry’s Cider Company{' '}
                <a href="https://www.harryscidercompany.co.uk">
                  www.harryscidercompany.co.uk
                </a>
              </li>
              <li>
                Bere Cider Company{' '}
                <a href="https://www.berecidercompany.com">
                  www.berecidercompany.com
                </a>
              </li>
              <li>
                Burrow Hill Cider Company{' '}
                <a href="https://www.somersetciderbrandy.com">
                  www.somersetciderbrandy.com
                </a>
              </li>
              <li>
                Heck’s Farmhouse Cider{' '}
                <a href="https://www.heckscider.com">www.heckscider.com</a>
              </li>
              <li>
                Parsons Choice Cider{' '}
                <a href="https://www.parsonschoicecider.co.uk">
                  www.parsonschoicecider.co.uk
                </a>
              </li>
              <li>
                Rich’s Farmhouse Cider{' '}
                <a href="https://www.richscider.co.uk">www.richscider.co.uk</a>
              </li>
            </ul>
          </div>
          <div className={'museum'}>
            <h5>Museums</h5>
            <ul>
              <li>
                <a href={'https://www.haynesmotormuseum.com'}>
                  Haynes Motor Museum-Sparkford
                </a>
              </li>
            </ul>
            <p>
              Haynes Museum is located just off the A303 at Sparkford. It is the
              UK’s biggest exhibition of cars & motorbikes dating from 1900 to
              today. Open 361 days of the year there is a Shop, Café and
              Childrens play area. Suitable for all ages.
            </p>
            <ul>
              <li>
                <a href={'https://www.fleetairarm.com/'}>
                  Fleet Air Arm Museum
                </a>
              </li>
            </ul>
            <p className={'fleet'}>
              The Fleet Air Arm Museum is devoted to the history of British
              naval aviation. It has an extensive collection of military and
              civilian aircraft, aero engines, models of aircraft and Royal Navy
              ships, and paintings and drawings related to naval aviation.
            </p>
          </div>
          <div className={'langport'}>
            <h5>Langport</h5>
            <p>
              The Heart of The Somerset Levels. A historic market town central
              to numerous villages and within 30 mins drive of Taunton, Yeovil,
              &amp; Bridgwater, and less than and hours’ drive from the famous
              Jurassic Coast. Take a relaxing walk along the River Parrett or
              simply just sit and watch the small boats and kayaks sail past.
              Everything you need on your doorstep. <br></br>
              <br></br>There are many places to eat and drink, all within
              walking distance, and a great choice of ‘quirky’ independent shops
              to wander around. Free parking alongside the river is available
              all year round.
            </p>
          </div>
        </AttractionLayoutStyles>
      </SlimContainer>
    </AttractionStyles>
  )
}

export default Attractions
