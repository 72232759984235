import {useStaticQuery, graphql} from 'gatsby'
import React, {ReactElement} from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'

import Container from './Container'
// import GoogleApiWrapper from './GoogleApiWrapper'
import SectionHeader from './SectionHeader'

const LocationSectionStyles = styled.section`
  background-color: var(--white);
  text-align: center;
`

const LocationSectionContainerStyles = styled.section`
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: repeat(3, auto);
  grid-template-areas:
    'map'
    'address'
    'contact';
  grid-gap: 3rem;

  @media only screen and (min-width: 600px) {
    grid-template-columns: 50% 200px auto;
    grid-template-rows: repeat(3, 1fr);
    grid-template-areas:
      'map .       .'
      'map address contact'
      'map .       .';
  }

  font-size: var(--info-font-size);

  .info {
    display: block;

    strong {
      display: block;
      margin-bottom: 1rem;
      font-size: var(--base-font-size);
      line-height: var(--paragraph-font-size);
      color: var(--darkgreen);
    }

    ul {
      list-style-type: none;
      margin: 0;
      padding: 1rem;
    }
  }

  .address {
    grid-area: address;
  }

  .contact {
    grid-area: contact;

    @media only screen and (min-width: 600px) {
      border-left: 2px dashed green;
    }

    a {
      color: var(--black);
      margin-bottom: 0.3rem;
      line-height: var(--paragraph-font-size);
    }
  }
`

const MapContainerStyles = styled.div`
  display: block;
  grid-area: map;
  height: 100%;
  min-height: 400px;
  position: relative;
  border-radius: 2rem;
  overflow: hidden;
`

const Location = (): ReactElement => {
  const data = useStaticQuery(graphql`
    query {
      map: file(relativePath: {eq: "map.jpg"}) {
        childImageSharp {
          fixed(width: 600, height: 420, quality: 64) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `)
  return (
    <LocationSectionStyles>
      <Container>
        <>
          <SectionHeader>
            <>
              Get in <strong>touch</strong>
            </>
          </SectionHeader>
          <LocationSectionContainerStyles
            itemScope
            itemType="https://schema.org/LocalBusiness"
          >
            <MapContainerStyles>
              <Img
                className={'image'}
                fixed={data.map.childImageSharp.fixed}
                alt="A map displaying our location at the heart of Somerset"
              />
              {/* <GoogleApiWrapper /> */}
            </MapContainerStyles>
            <div id={'contact'} className="info address">
              <ul
                itemProp="address"
                itemScope
                itemType="https://schema.org/PostalAddress"
              >
                <li>
                  <strong>ADDRESS</strong>
                </li>
                <li itemProp="name">Smith Cottages Langport</li>
                <li itemProp="streetAddress">Bow Street</li>
                <li itemProp="addressLocality">Langport</li>
                <li itemProp="postalCode">TA10 9PH</li>
                <li itemProp="addressCountry">United Kingdom</li>
              </ul>
            </div>
            <div className="info contact">
              <ul>
                <li>
                  <strong>CALL US AT</strong>
                </li>
                <li itemProp="telephone">
                  <a href="tel:+447786 012 176">(+44) 7786 012 176</a>
                </li>
              </ul>
              <ul>
                <li>
                  <strong>EMAIL ADDRESS</strong>
                </li>
                <li>
                  <a
                    itemProp="email"
                    href="mailto:smithcottageslangport@gmail.com?subject=Cottage Availability"
                  >
                    smithcottageslangport@gmail.com
                  </a>
                </li>
              </ul>
            </div>
          </LocationSectionContainerStyles>
        </>
      </Container>
    </LocationSectionStyles>
  )
}

export default Location
