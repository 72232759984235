import React, {ReactElement} from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'

import Container from './Container'

import Button from './Button'
import {graphql, useStaticQuery} from 'gatsby'

const IntroStyles = styled.section`
  grid-area: intro;
  max-width: 100%;
  background-color: var(--green);
  color: var(--white);
`

const IntroLayoutStyles = styled.section`
  display: grid;
  grid-template-columns: auto;
  grid-gap: 3rem;
  grid-template-areas: 'description';

  @media only screen and (min-width: 600px) {
    grid-template-columns: 5fr 2fr;
    grid-gap: 11rem;
    grid-template-areas: 'description image';
  }

  div.description {
    grid-area: description;

    font-weight: 200;

    h2 {
      font-size: var(--primary-header-font-size);
      margin-bottom: 2rem;
      line-height: var(--primary-header-font-size);
    }

    p {
      font-size: var(--paragraph-font-size);
      line-height: var(--paragraph-line-height);

      margin-bottom: 2rem;
    }

    ul {
      margin-bottom: 3rem;

      @media only screen and (min-width: 600px) {
        margin-bottom: 5rem;
      }
      li {
        padding: 0.3rem;
      }
    }
  }

  .image {
    border-radius: 1rem;
    position: absolute !important;
    top: -9999px !important;
    left: -9999px !important;

    @media only screen and (min-width: 600px) {
      position: relative !important;
      top: auto !important;
      left: auto !important;
    }
  }
`
const Intro = (): ReactElement => {
  const data = useStaticQuery(graphql`
    query {
      courtyard: file(
        relativeDirectory: {eq: "gallery"}
        name: {eq: "courtyard"}
      ) {
        childImageSharp {
          fixed(width: 275, height: 450, cropFocus: WEST) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `)

  return (
    <IntroStyles>
      <Container>
        <IntroLayoutStyles>
          <div id="about" className={'description'}>
            <h2>
              Fully equipped
              <br />
              self-catering cottages....
            </h2>
            <p>
              Positioned around a courtyard, traditionally called
              &lsquo;Yards&rsquo; named after the owners, Smith Cottages dates
              back to the late 17th Century. Originally an Ale House with
              &lsquo;Rooms&rsquo; above. It was frequented by Sailors visiting
              the town in the days when Langport traded with Holland. Small
              boats would arrive along the River Parrett, which in those days
              was a much larger water course.
            </p>
            <p>Suitable for:</p>
            <ul>
              <li>Short breaks</li>
              <li>Holidays</li>
              <li>Professionals working away from home</li>
            </ul>
            <Button
              isExternal={true}
              link="mailto:smithcottageslangport@gmail.com?subject=Cottage Availability"
              text={'Click here to email us'}
            />
          </div>
          <Img
            className={'image'}
            fixed={data.courtyard.childImageSharp.fixed}
            alt="Photo looking down our beautiful courtyard"
          />
        </IntroLayoutStyles>
      </Container>
    </IntroStyles>
  )
}

export default Intro
