import React, {ReactElement} from 'react'
import {graphql} from 'gatsby'

import SEO from '../components/SEO'
import Intro from '../components/Intro'
import Properties from '../components/Properties'
import Location from '../components/Location'
import Attractions from '../components/Attractions'
import GallerySection from '../components/GallerySection'
import {FixedObject} from 'gatsby-image'

export interface Image {
  node: {
    childImageSharp: FixedObject
  }
}

interface IndexProps {
  data: {
    allFile: {
      edges: Image[]
    }
  }
}

const IndexPage = ({data}: IndexProps): ReactElement => {
  return (
    <>
      <SEO title="Home" />
      <Intro />
      <Properties />
      <Attractions />
      <GallerySection data={data} />
      <Location />
    </>
  )
}

export const query = graphql`
  query ImagesForGalleryHomePage {
    allFile(filter: {relativeDirectory: {eq: "gallery"}}) {
      edges {
        node {
          childImageSharp {
            thumb: fluid(quality: 64, maxWidth: 125, maxHeight: 125) {
              ...GatsbyImageSharpFluid_withWebp
              originalName
            }
            full: fluid(maxWidth: 1024) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`

export default IndexPage
