import React, {useState, useEffect, ReactElement, MouseEvent} from 'react'
import Img from 'gatsby-image'
import styled from 'styled-components'

import Container from './Container'

import {
  commonAmenities,
  property1,
  property2,
  property3,
  PropertyInfo,
} from './PropertyInfo'

import {useStaticQuery, graphql} from 'gatsby'
import SectionHeader from './SectionHeader'

const PropertyStyles = styled.section`
  text-align: center;

  @media only screen and (min-width: 600px) {
    text-align: left;
  }

  .imageContainer {
    margin-bottom: 4rem;

    img {
      display: block;
      margin: 0 auto;
      border-radius: 1rem;
    }
  }

  strong {
    font-size: var(--secondary-header-font-size);
  }

  .bedrooms {
    font-size: var(--info-font-size);
    margin-bottom: 0.5rem;
    display: block;
  }

  .description {
    font-size: var(--info-font-size);
    line-height: var(--info-line-height);
  }

  ul {
    list-style-type: square;
    padding-left: 2rem;
    column-count: 2;
  }

  ul li {
    font-size: var(--paragraph-font-size);
    margin-bottom: 0.3rem;
    text-align: left;
  }

  hr {
    border-top: 2px solid var(--darkgrey);
    border-radius: 2px;
  }

  a {
    font-weight: bold;
    color: #000;
    font-size: 1.5rem;
    text-align: center;
    width: 100%;
    display: block;
  }
`

const Property = (props: PropertyInfo): ReactElement => {
  const [randomAmenities, setRandomAmenities] = useState<string[]>([])

  const shuffledAmenities = props.amenities.sort(() => Math.random() - 0.5)
  useEffect(() => {
    // setRandomAmenities(shuffledAmenities.slice(0, 10))
    setRandomAmenities(shuffledAmenities)
  }, [])

  return (
    <PropertyStyles>
      <div className="imageContainer">{props.children}</div>
      <div className="information">
        <span className="bedrooms"> [{props.bedrooms}]</span>
        <strong>{props.name}</strong>
        <p className="description">{props.description}</p>
        <ul>
          {randomAmenities.map((amenity, index) => (
            <li key={index}>{amenity}</li>
          ))}
        </ul>
      </div>
    </PropertyStyles>
  )
}

const PropertiesStyles = styled.section`
  grid-area: intro;
  background-color: var(--lightgrey);
  color: var(--black);
  text-align: center;
`

const PropertyLayoutStyles = styled.section`
  div.properties {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, 1fr);
    grid-gap: 5rem;

    @media only screen and (min-width: 600px) {
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: 1fr;
      grid-gap: 11rem;
    }
  }

  h5 {
    font-size: var(--base-font-size);
    margin: 3rem 0;
    font-weight: 700;
    text-align: left;

    @media only screen and (min-width: 600px) {
      margin: 3rem auto;
      text-align: center;
    }
  }

  ul.commonAmenities {
    list-style-type: square;
    margin-top: 3rem;
    padding-left: 2rem;
    column-count: 2;

    @media only screen and (min-width: 600px) {
      margin: 3rem 10rem;
      list-style-type: none;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    }

    li {
      font-size: var(--paragraph-font-size);
      margin-bottom: 0.3rem;
      text-align: left;

      padding-left: 0;

      @media only screen and (min-width: 600px) {
        text-align: center;
      }
    }
  }
`

const Properties = (): ReactElement => {
  const data = useStaticQuery(graphql`
    query {
      no2: file(relativeDirectory: {eq: "gallery"}, name: {eq: "no2"}) {
        childImageSharp {
          fixed(width: 300, height: 375) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      no4: file(relativeDirectory: {eq: "gallery"}, name: {eq: "no4"}) {
        childImageSharp {
          fixed(width: 300, height: 375) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      no5: file(relativeDirectory: {eq: "gallery"}, name: {eq: "no5"}) {
        childImageSharp {
          fixed(width: 300, height: 375) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `)

  return (
    <PropertiesStyles>
      <Container>
        <PropertyLayoutStyles>
          <SectionHeader>
            <>
              Our <strong>Properties</strong>
            </>
          </SectionHeader>
          <div className={'properties'}>
            <Property {...property1}>
              <Img
                fixed={data.no2.childImageSharp.fixed}
                alt={
                  'Photograph of number 2. Our first floor 2 bedroom apartment.'
                }
              />
            </Property>
            <Property {...property2}>
              <Img
                fixed={data.no4.childImageSharp.fixed}
                alt={
                  'Photograph of Number 4. One of our self catering studio cottages.'
                }
              />
            </Property>
            <Property {...property3}>
              <Img
                fixed={data.no5.childImageSharp.fixed}
                alt={
                  'Photograph of Number 5. One of our self catering studio cottages.'
                }
              />
            </Property>
          </div>
          <hr></hr>
          <h5>All properties also benefit from:</h5>
          <ul className={'commonAmenities'}>
            {commonAmenities.map((amenity, index) => (
              <li key={index}>{amenity}</li>
            ))}
          </ul>
        </PropertyLayoutStyles>
      </Container>
    </PropertiesStyles>
  )
}

export default Properties
