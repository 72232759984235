import {ReactElement} from 'react'

export interface PropertyInfo {
  name: string
  description: string
  bedrooms: string
  amenities: string[]
  img: string
  children?: ReactElement
}

export const commonAmenities = [
  'Secure parking',
  'Suitable for children',
  'Linens Provided',
  'Towels provided',
  'Iron & ironing board',
  'Shared garden',
  'Shared laundry area',
  'Shared outdoor dining area',
  'Shared patio',
  'Short term rentals',
  'Slow cooker',
  'Local Guides/Maps',
  'Cycle hire nearby',
  'Fishing nearby',
  'Golf course within 30 mins',
  'Horse riding nearby',
  'Nearby tennis courts',
  'Nearby boat hire',
]

export const property1 = {
  name: 'No. 2 Smith Cottages',
  description: `Overlooking Bow Street this first floor apartment is in the oldest and most quirky part of our property, this newly refurbished 2 bedroomed character apartment offers comfortable and cosy accommodation for guests staying with us for a weekend break, a week stay or for longer. Reputedly a former house of ill repute and apparently haunted this apartment above an old Ale House has all the modern facilities visitors to Langport would need to make their stay most enjoyable.`,
  bedrooms: '2 Kings or 4 singles, 1 Sofabed',
  amenities: [
    'Heating',
    'Dryer',
    'Freezer',
    'Refrigerator',
    'Highchair',
    'Kettle',
    'Microwave',
    'Cooker',
    'Toaster',
    'TV',
    'Wi-Fi',
  ],
  img: 'no2.jpeg',
}

export const property2 = {
  name: 'No. 4 Smith Cottages',
  description: `Self catering studio cottage originally part of the old stables for the Ale house. Situated overlooking the courtyard at the rear of the property. Ideal for couples, single friends or young families. Fully equiped with zip linked beds creating 1 King size or 2 single beds. The cottage has a shower room, main living/sleeping room with sofa, table and chairs and kitchenette area. A travel cot or fold up bed is available for a young child.`,
  bedrooms: '1 King or 2 singles, 1 Travel cot',
  amenities: [
    'Heating',
    'Dryer',
    'Freezer',
    'Refrigerator',
    'Hairdryer',
    'Highchair',
    'Kettle',
    'Microwave',
    'Cooker',
    'Toaster',
    'TV',
    'Wi-Fi',
  ],
  img: 'no3.jpeg',
}

export const property3 = {
  name: 'No. 5 Smith Cottages',
  description: `Self catering studio cottage originally part of the old stables for the Ale house. Situated overlooking the patio at the rear of the property. Ideal for couples, single friends or young families. Fully equiped with zip linked beds creating 1 King size or 2 single beds. The cottage has a shower room, main living/sleeping room with sofa, table and chairs and kitchenette area. A travel cot or fold up bed is available for a young child.`,
  bedrooms: '1 King or 2 singles, 1 Travel cot',
  amenities: [
    'Heating',
    'Dryer',
    'Freezer',
    'Refrigerator',
    'Hairdryer',
    'Highchair',
    'Kettle',
    'Microwave',
    'Cooker',
    'Toaster',
    'TV',
    'Wi-Fi',
  ],
  img: 'no5.jpeg',
}
